import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Option } from '../data/option';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {apiUrl} from '../config';

@Injectable()
export class PageAccueilService  {
    

  private time: Date = new Date;

  constructor(private http: HttpClient){

  }
  
  header = {
    headers: new HttpHeaders({

      'x-access-token': '44'
    })
  };
 
  
  getBlocksAccueil(){
    return this.http.get(apiUrl + '/Accueil/Bloc/getall', this.header);

  }
  getOptionsHome(){
    return this.http.get(apiUrl + '/Accueil/Carre/getall', this.header);

  }
  getSliders(){
    return this.http.get(apiUrl + '/Accueil/Slider/getall', this.header);
  
 }

  


}
