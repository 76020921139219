import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertLink'
  })
  export class convertLink implements PipeTransform {
    transform(value: string) {
        let returnString="";
        returnString=  value.replace(/-/g,"_");
       return returnString.replace(/ /g,"-");
    }
  
 
  }