import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Option } from '../data/option';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {apiUrl} from '../config';

@Injectable()
export class ServiceContact  {
    

  private time: Date = new Date;

  constructor(private http: HttpClient){

  }
  
  header = {
    headers: new HttpHeaders({

      'x-access-token': '44'
    })
  };

 GetContact(){
  return this.http.get(apiUrl + '/ContactNous/getall', this.header);

 }
  GetContactInfo(){
    return this.http.get(apiUrl + '/Contact/info/getall', this.header);

}
  SendContact(body,newsletter){
    body.newsletter=newsletter;
  
    console.log(body);
    return this.http.post(apiUrl + '/Contact/send',body, this.header);

  }
 
  

  


}
