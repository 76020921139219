import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { apiUrl } from '../config';
import { PagerService } from '../services/page-service';
import { ServiceRealisation } from '../services/realisation-service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-realisation',
  templateUrl: './realisation.component.html',
  styleUrls: ['./realisation.component.css']
})
export class RealisationComponent implements OnInit {
  baseUrl=apiUrl;
  realisations:any[];


  data:any[];
  pager: any = {};
  pagedItems: any[];
  constructor(private router: Router, private pagerService: PagerService,private _serviceRealisation:ServiceRealisation,private titleService: Title) { 
    this.titleService.setTitle("Liste des réalisations  | Nobel Immobilier");
this.GetAllRealisation();
   }
  
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
  GetAllRealisation(){
this._serviceRealisation.GetAllRealisation().subscribe((res:any[])=>{
  console.log(res);
  this.realisations=res;
  this.data=res;
  this.setPage(1);

})
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.realisations.length, page);

    // get current page of items
    this.pagedItems = this.realisations.slice(this.pager.startIndex, this.pager.endIndex + 1);
    console.log('Slice from :'+this.pager.startIndex+ ' to '+(+this.pager.endIndex + 1));

}

}
