import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Option } from '../data/option';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {apiUrl} from '../config';

@Injectable()
export class ServiceLocation  {
    

  private time: Date = new Date;

  constructor(private http: HttpClient){

  }
  
  header = {
    headers: new HttpHeaders({

      'x-access-token': '44'
    })
  };

 
  GetAllLocation(){
    return this.http.get(apiUrl + '/Location/getall', this.header);

}
  GetLocationById(id){
    return this.http.get(apiUrl + '/Location/getid/'+id, this.header);

  }
  

  


}
