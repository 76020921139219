//ProgrammesDetailsComponent
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { ProgrammesNeufsService } from 'src/app/services/programmes-neufs.service';
import {apiUrl} from '../../../app/config';
import { PageAccueilService } from 'src/app/services/page-accueil.service';
import { multiFilter } from 'src/app/utils/filterArray';
import { Title } from '@angular/platform-browser';

 @Component({
  selector: 'app-programmes-details',
  templateUrl: './programmes-details.component.html',
  styleUrls: ['./programmes-details.component.css']
})
 
export class ProgrammesDetailsComponent implements OnInit {
  displayedColumns = ['id', 'type', 'surface', 'etage','plan','prix','disponibilite'];
  dataSource: MatTableDataSource<any[]>;
  private sub: any;
  idLocation:string;
  options:any[];
etages:any[]=[];
type_logements:any[]=[];
disponibilites:any[]=[];

  baseUrl:string;
  programme:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  lat: number = 51.678418; 
  lng: number = 7.809007;
  data:any;
  etage_logement_value:string='';
  type_logement_value:string='';
  disponiblite_value:string='';
  constructor(private route: ActivatedRoute,private _programmesService:ProgrammesNeufsService,private _servicePageAccueil:PageAccueilService,private titleService: Title) { 
    this.baseUrl=apiUrl;
    this.sub = this.route.params.subscribe(params => {
      this.idLocation = params['name']; 
    });
     // Create 100 users
    this.GetAllOptions();

     this._programmesService.GetLocationByName(this.idLocation).subscribe((result:any)=>{
      this.titleService.setTitle(result.nom+" | Nobel Immobilier");

       console.log(result);
      this.dataSource = new MatTableDataSource(result.logementdispo);
      this.data=result.logementdispo;
      if(!result.icons)
      this.programme.icons=[];
      console.log(result);
      this.programme=result;
      

      this.lat=+this.programme.map[0];
      this.lng=+this.programme.map[1];
      console.log("********************///////////// Location *****************/////");
      console.log(this.lat);
      console.log(this.lng)
      result.logementdispo.forEach((loge:any) => {
          this.etages.push(loge.etage);
          this.type_logements.push(loge.type);
          this.disponibilites.push(loge.disponibilite);
      });
      this.disponibilites=this.removeDups(this.disponibilites);
      this.type_logements=this.removeDups(this.type_logements);
      this.etages=this.removeDups(this.etages);

     })
  }
   removeDups(names) {
    let unique = {};
    var sorted = [];
for (var i = 0; i < names.length; i++) {
    sorted.push(names[i].toUpperCase());
}
sorted.sort();
sorted.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }
  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Données par page.';

  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  filterProgrammes(){
    var filter={
      etage:this.etage_logement_value,
      type:this.type_logement_value,
      disponibilite:this.disponiblite_value
    }
    //console.log(multiFilter(this.programme.logementdispo,filter));
    this.data.logementdispo=multiFilter(this.programme.logementdispo,filter);
    /*
     this.data.logementdispo = this.programme.logementdispo.filter(function(item) {
      for (var key in filter) {
         if (filter[key] !=''&&filter[key] === item[key]){
          console.log(item[key]);
          return true;
        }
        else{
          return false;

        }
       
      }
    });
    */
    if(this.etage_logement_value==''&&this.type_logement_value==''&&this.disponiblite_value==''){
      this.data.logementdispo= this.programme.logementdispo;
    }
    this.dataSource = new MatTableDataSource(this.data.logementdispo);
  }
  GetAllOptions(){
    this._servicePageAccueil.getOptionsHome().subscribe((res:any) => {
      this.options=res;
      console.log(this.options);
    });
  }
}

/** Builds and returns a new User. */

