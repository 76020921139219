import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SocieteService } from 'src/app/services/societe.service';
import {apiUrl} from '../../../app/config';

@Component({
  selector: 'app-option1',
  templateUrl: './option1.component.html',
  styleUrls: ['./option1.component.css']
})
export class Option1Component implements OnInit {
  societe:any;
  options:any;
  arguments:any[];
  baseUrl:string;
  idSociete:string;
  newArray:any[]=[];
  constructor(private router: Router,private _serviceAbout:SocieteService) { 
    this.baseUrl=apiUrl;
  }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        this.geOption1();

    }

  geOption1(){
    //GetArgumentOption
    this._serviceAbout.getSociete().subscribe((res:any) => {
      this.societe=res[0];
      this.idSociete=res[0]._id;
      this._serviceAbout.getOptionsHome(this.idSociete).subscribe((res:any)=>{
       this.options=res[0];
       this._serviceAbout.GetArgumentOption(this.options._id).subscribe((res:any) => {
         
      this.arguments=res;
      let   k:number=3;

      for (let i = 0; i < this.arguments.length; i += k) {

        this.newArray.push({ items: this.arguments.slice(i, i + k) });
        console.log(this.newArray);
      }
       });
       });
    });
  }

}
