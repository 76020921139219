import '../polyfills';
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
import {TruncatePipe} from '../app/utils/truncate';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { SliderComponent } from './core/slider/slider.component';
import {FindYourHomeComponent} from './home/findyourhome/findyourhome.component';
import {LastPropertiesComponent} from './home/lastproperties/lastproperties.component';
import {MostPlacesComponent} from './home/mostplaces/mostplaces.component';
import {ServicesComponent} from './home/services/services.component';
import {FeedBacksComponent} from './home/feedbacks/feedbacks.component';
import {BlogPostsComponent} from './home/blogposts/blogposts.component';
import {PartenaireComponent} from './home/partenaire/partenaire.component';
import {NewsLetterComponent} from './home/newsletter/newsletter.component';
import {MenuComponent} from './core/menu/menu.component';
import {ContactComponent} from './home/contact/contact.component';
import {ContactPageComponent} from './contact/contact.component';

import {PresentationComponent} from './home/presentation/presentation.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent,Option1Component,Option2Component,Option3Component } from './about';
import { ProgrammesComponent } from './programmes/programmes.component';
import { ProgrammesDetailsComponent } from './programmes/programmes-details/programmes-details.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CdkTableModule} from '@angular/cdk/table';
import {AgmCoreModule} from '@agm/core'
import {LocationComponent } from './locations/location.component';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';
import { RealisationComponent } from './realisation/realisation.component';
import { RealisationDetailsComponent } from './realisation/realisation-details/realisation-details.component';
import { BlogComponent } from './blog/blog.component';
import {BlogDetailsComponent} from './blog/blog-details/blog-details.component';
import { PageAccueilService } from './services/page-accueil.service';
import { SocieteService } from './services/societe.service';
import { ProgrammesNeufsService } from './services/programmes-neufs.service';
import { PagerService } from './services/page-service';
import { ServiceLocation } from './services/location-service';
import { ServiceRealisation } from './services/realisation-service';
import { ServiceBlog } from './services/blog-service';
import { ConvertirDate } from './utils/converMonthName';
import { ServiceContact } from './services/contact-service';
import { MentionLegalesService } from './services/mentions-legales-service';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales';
import { convertLink } from './utils/convertLink';
const SERVICES = [PageAccueilService,SocieteService,ProgrammesNeufsService,PagerService,ServiceLocation,ServiceRealisation,ServiceBlog,ServiceContact,MentionLegalesService]
@NgModule({
  exports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  declarations: [
    TruncatePipe,
    ConvertirDate,
    convertLink,

    AppComponent,
    FooterComponent,
    HeaderComponent,
    SliderComponent,
    LastPropertiesComponent,
    FindYourHomeComponent,
    MostPlacesComponent,
    ServicesComponent,
    FeedBacksComponent,
    BlogPostsComponent,
    PartenaireComponent,
    NewsLetterComponent,
    MenuComponent,
    ContactComponent,
    ContactPageComponent,
    PresentationComponent,
    HomeComponent,
    AboutComponent,
    Option1Component,
    Option2Component,
    Option3Component,
    ProgrammesComponent,
    ProgrammesDetailsComponent,
    LocationComponent,
    LocationDetailsComponent,
    RealisationComponent,
    RealisationDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    MentionsLegalesComponent
  ],
  imports: [
    SlickCarouselModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyCXt4c81qUYt3bPhAFt7TsNjO4vC-WtSTE'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatTableModule
    
  ],
  providers: [...SERVICES],
  bootstrap: [AppComponent]
})
export class AppModule { }

