import { Component, OnInit } from '@angular/core';
import { ServiceContact } from 'src/app/services/contact-service';
declare var 	initSlickCarousel: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
   contact:any;
  constructor(private _serviceContact:ServiceContact) { }

  ngOnInit() {
    this._serviceContact.GetContact().subscribe((res:any)=>{
      this.contact=res;

    });
  }

}
