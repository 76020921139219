//ProgrammesDetailsComponent
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { apiUrl } from 'src/app/config';
import { ServiceLocation } from 'src/app/services/location-service';
import {$, jQuery} from 'jquery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent implements OnInit {
  navigationSubscription;
  baseUrl:string;
  location:any;
  private sub: any;
  idLocation:string;
  pdfStatus:number=0;
 
  constructor(private router: Router,private activatedRoute: ActivatedRoute,private _serviceLocation:ServiceLocation,private titleService: Title) { 

    this.baseUrl=apiUrl;
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.idLocation = params['idLocation']; 
      this.pdfStatus = params['pdf']; 
   
    });
  }
  ngOnInit() {
    this._serviceLocation.GetLocationById(this.idLocation).toPromise().then((result:any)=>{
      console.log(result);
      console.log(result);
  this.location=result;
  this.titleService.setTitle(result.titre_location+" | Nobel Immobilier");


    })
    if(this.pdfStatus>0){
      console.log("Hello ...");
      $(".container").css( { "padding-right" : "44px!important", "padding-left" : "44px!important" } );
 
    }
  }
}