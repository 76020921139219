import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiUrl } from 'src/app/config';
import { ServiceBlog } from 'src/app/services/blog-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  navigationSubscription;
  baseUrl:string;
  blog:any;
  private sub: any;
  idBlog:string;
  blogs:any[];
  comments:any[];
  registerForm: FormGroup;
  submitted = false;
 
  constructor(private formBuilder: FormBuilder,
    private router: Router,private activatedRoute: ActivatedRoute,private _serviceBlog:ServiceBlog,private titleService: Title) { 

    this.baseUrl=apiUrl;
    this.sub = this.activatedRoute.params.subscribe(params => {
     this.idBlog = params['idBlog'].replace(/-/g," "); 
     console.log(this.idBlog);

     this.idBlog = this.idBlog.replace(/_/g,"-"); 
  
      console.log(this.idBlog);
      this._serviceBlog.GetBlogByName(this.idBlog).subscribe((result:any)=>{
        this.titleService.setTitle(result.titre+" | Nobel Immobilier");

    this.blog=result;
    this.blog.nbr_comment=0;
    this._serviceBlog.GetAllCommentByBlogId(this.idBlog).subscribe((re:any[])=>{
      this.blog.nbr_comment=re.length;
    });
  
     this.GetAllBlog();
      })
    });
  }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      email: ['', Validators.email],
      numtel: ['', Validators.required],
      siteweb: ['', Validators.required],
      comment: ['', Validators.required]
    });
    this.GetAllComment();

  }
  get f() { return this.registerForm.controls; }

  GetAllBlog(){
    this._serviceBlog.GetAllBlog().subscribe((res:any)=>{
      res.nb_comment=0;
      let index=0;
      res.forEach(element => {
          this._serviceBlog.GetAllCommentByBlogId(element._id).subscribe((commentres:any[])=>{
            element.nb_comment=commentres.length;
          })
         
          res[index]=element;
     index++;
      });
    

     
      console.log(res);
        this.blogs=res;
        const targetIdx = this.blogs.map(item => item._id).indexOf(this.blog._id);
        this.blogs.splice(targetIdx,1);

      
    })
  }
  GetAllComment(){
    this._serviceBlog.GetAllCommentByBlogId(this.idBlog).subscribe((res:any[])=>{
      this.comments=res;
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    else{
    
      this._serviceBlog.AddCommentByBlogId(this.idBlog,this.registerForm.value).subscribe((res:any)=>{
        this.GetAllComment();
        this.registerForm = this.formBuilder.group({
          nom: ['', Validators.required],
          email: ['', Validators.email],
          numtel: ['', Validators.required],
          siteweb: ['', Validators.required],
          comment: ['', Validators.required]
        });  
      })
    }
  
  }

}
