import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent,Option1Component,Option2Component,Option3Component } from './about';
import { ProgrammesComponent } from './programmes/programmes.component';
import { ProgrammesDetailsComponent } from './programmes/programmes-details/programmes-details.component';
import { LocationComponent } from './locations/location.component';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';
import { RealisationComponent } from './realisation/realisation.component';
import { RealisationDetailsComponent } from './realisation/realisation-details/realisation-details.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './home/contact/contact.component';
import {BlogDetailsComponent} from './blog/blog-details/blog-details.component';
import { ContactPageComponent } from './contact/contact.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales';

const routes: Routes = [{
  path:'',
  component:HomeComponent,
},{
  path:'about',
  component:AboutComponent,
},{
  path:'guide',
  component:Option1Component,
},{
  path:'prestation',
  component:Option2Component,
}
,{
  path:'avantage',
  component:Option3Component,
}
,{
  path:'programmes',
  component:ProgrammesComponent,
}
,{
  path:'programmes-details/:name',
  component:ProgrammesDetailsComponent,
},
{
  path:'location',
  component:LocationComponent,
},
{
  path:'location-details/:idLocation',
  component:LocationDetailsComponent,
},
{
path:'location-details/:idLocation/:pdf',
component:LocationDetailsComponent,
},{
  path:'realisation',
  component:RealisationComponent,
},
{
  path:'realisation-details',
  component:RealisationDetailsComponent,
},
{
  path:'realisation-details/:idRealisation',
  component:RealisationDetailsComponent,
},{
  path:'blog',
  component:BlogComponent
}
,{
  path:'blog-details/:idBlog',
  component:BlogDetailsComponent
},{
  path:'contact',
  component:ContactPageComponent
}
,{
  path:'mentions-legales',
  component:MentionsLegalesComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
