import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagerService } from '../services/page-service';
import { ServiceBlog } from '../services/blog-service';
import { apiUrl } from '../config';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  baseUrl:string=apiUrl;
  blogs:any[]=[];

  data:any[];
  pager: any = {};
  pagedItems: any[];
  constructor(private router: Router, private pagerService: PagerService,private _serviceBlog:ServiceBlog,private titleService: Title) { 
    this.titleService.setTitle("Liste des articles  | Nobel Immobilier");

this.GetAllBlog();
  }

  ngOnInit() {
  }
  GetAllBlog(){
    this._serviceBlog.GetAllBlog().subscribe((res:any)=>{
      res.nb_comment=0;
      let index=0;
      res.forEach(element => {
          this._serviceBlog.GetAllCommentByBlogId(element._id).subscribe((commentres:any[])=>{
            element.nb_comment=commentres.length;
          })
         
          res[index]=element;
     index++;
      });
    

     
      console.log(res);
        this.blogs=res;
        let inde=0;
        this.blogs.forEach(blog=>{
          this.blogs[inde].descriptionCourte=blog.description.substr(0,(blog.description.replace(/<\/?[^>]+>/ig, " ").length /5))+"...";
          console.log(this.blogs[inde].description.length);

          inde++;
        })
        console.log(this.blogs);
        this.data=res;
      
        this.setPage(1);
    })
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.blogs.length, page);

    // get current page of items
    this.pagedItems = this.blogs.slice(this.pager.startIndex, this.pager.endIndex + 1);
    console.log('Slice from :'+this.pager.startIndex+ ' to '+(+this.pager.endIndex + 1));

}
}
