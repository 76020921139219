import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Option } from '../data/option';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {apiUrl} from '../config';

@Injectable()
export class ServiceBlog  {
    

  private time: Date = new Date;

  constructor(private http: HttpClient){

  }
  
  header = {
    headers: new HttpHeaders({

      'x-access-token': '44'
    })
  };

 
  GetAllBlog(){
    return this.http.get(apiUrl + '/Article/getall', this.header);

}
  GetBlogByName(id){
    return this.http.get(apiUrl + '/Article/getByName/'+id, this.header);

  }
  GetBlogById(id){
    return this.http.get(apiUrl + '/Article/getid/'+id, this.header);

  }
  GetAllCommentByBlogId(idBlog){
    return this.http.get(apiUrl + '/Article/comment/getall/'+idBlog, this.header);

  }
  AddCommentByBlogId(idBlog,comment){
    console.log("adding new comment");
    return this.http.post(apiUrl + '/Article/comment/Ajout/'+idBlog,comment, this.header);

  }
  

  


}
