import { Component, OnInit } from '@angular/core';
import { SocieteService } from '../services/societe.service';
import {apiUrl} from '../../app/config';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  societe:any;
  options:any[];
  baseUrl:string;
  idSociete:string;
  constructor(private _serviceAbout:SocieteService,private titleService: Title) { 
    this.titleService.setTitle("Société  | Nobel Immobilier");
    this.baseUrl=apiUrl;
    this.getSocietePage();

   }

  ngOnInit() {
  }
  getSocietePage()
  {
    
   this._serviceAbout.getSociete().subscribe((res:any) => {
     this.societe=res[0];
     this.idSociete=res[0]._id;
     console.log(res[0]);
     this._serviceAbout.getOptionsHome(this.idSociete).subscribe((res:any)=>{
      this.options=res;
      });
   });

  }
  

}
