import { Component, OnInit } from '@angular/core';
import { ServiceContact } from '../services/contact-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactPageComponent implements OnInit {
 
  contactInfo:any;
  contactForm: FormGroup;
  submitted_infromation_generale = false;
  newsletter=false;
  lat: number = 51.678418; 
  lng: number = 7.809007;
  constructor( private router:Router,
       private formBuilder: FormBuilder,
    private _serviceContact:ServiceContact,private titleService: Title) { 
      this.titleService.setTitle("Contact  | Nobel Immobilier");
      this.contactForm = this.formBuilder.group({
        civilite: ['', Validators.required],
        nom: ['', Validators.required],
        prenom: ['', Validators.required],
        email: ['', Validators.email],
        numtel: ['', Validators.required],
        description: [''],
           
    });
    
   }

  ngOnInit() {
    
    this._serviceContact.GetContactInfo().subscribe((res:any)=>{
      this.contactInfo=res;
      this.lat=+this.contactInfo.lattitude;
      this.lng=+this.contactInfo.longtitude;
      console.log(this.contactInfo);
     });
  }
  get f() { return this.contactForm.controls; }
  onSubmitInfosprincipale() {
    this.submitted_infromation_generale = true;
  
    // stop here if form is invalid
    if (this.contactForm.invalid) {
         return;
    }
    else{
      this._serviceContact.SendContact(this.contactForm.value,this.newsletter).subscribe((res:any)=>{
        this.router.navigate(["/"]);
      })
    }
  }
}
