import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ConvertirDate'
  })
  export class ConvertirDate implements PipeTransform {
    transform(value: string) {
        let returnString="";
        const t = new Date(value);
         return  'Le '+ t.getDate()+' '+this.returnNumberToMonth(t.getMonth().toString())+' '+ t.getFullYear();
       
    }
    transrformToRawDate(value:string){
        const t = new Date(value);
        return   t.getDate()+'-'+t.getMonth().toString()+'-'+ t.getFullYear();
    }
    returnNumberToMonth(value:string){
        switch (value) {
            case '1':
                return "Janvier"
                break;
                case '2':
                    return "Février"
                    break;
                    case '3':
                        return "Mars"
                        break; 
                        case '4':
                        return "Avril"
                        break; 
                        case '5':
                        return "Mai"
                        break; 
                        case '6':
                        return "Juin"
                        break; 
                        case '7':
                        return "Juillet"
                        break; 
                        case '8':
                        return "Août"
                        break; 
                        case '9':
                        return "Septembre"
                        break;
                        case '10':
                            return "Octobre"
                            break;
                            case '11':
                                return "Novembre"
                                break;
                                case '12':
                                    return "Décembre"
                                    break;
            default:
                return "Janvier"

        }
    }
  }