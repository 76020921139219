import { Component, OnInit } from '@angular/core';
declare var 	initSlickCarousel: any;

@Component({
  selector: 'app-findhome',
  templateUrl: './findyourhome.component.html',
  styleUrls: ['./findyourhome.component.css']
})
export class FindYourHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
