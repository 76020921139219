//ProgrammesDetailsComponent
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { ProgrammesNeufsService } from '../services/programmes-neufs.service';
import { PagerService } from '../services/page-service';
import { ServiceLocation } from '../services/location-service';
import { apiUrl } from '../config';
import { multiFilter } from '../utils/filterArray';
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  baseUrl:string;
  locations:any[];
  data:any[];
  show = true;

  ville_value:string='';
  type_logement_value:string='';
  type_loyer_value:string='';
   // pager object
   pager: any = {};

   // paged items
   pagedItems: any[];

  constructor(private router: Router,private _serviceLocation:ServiceLocation, private pagerService: PagerService,private titleService: Title) { 
    this.titleService.setTitle("Liste des locations  | Nobel Immobilier");
    this.baseUrl=apiUrl;
    

   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  this.GetAllLocation();
this.initIsoTop();
this.initPreLoader();

  }
  GetAllLocation(){
    this.show=true;

    this._serviceLocation.GetAllLocation().subscribe((result:any)=>{
     this.locations=result;
     this.data=result;
     this.setPage(1);
     console.log(result);

    });
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.locations.length, page);
 
    // get current page of items
    this.pagedItems = this.locations.slice(this.pager.startIndex, this.pager.endIndex + 1);
    console.log('Slice from :'+this.pager.startIndex+ ' to '+(+this.pager.endIndex + 1));
    this.show=false;

}
filterLocation(){
  var filter={
    ville:this.ville_value,
    type_location:this.type_logement_value,
    type_loyer:this.type_loyer_value
  }
   this.locations=multiFilter(this.data,filter);
/*
  this.locations= this.data.filter(function(item) {
    for (var key in filter) {
          console.log(item[key]+' : '+ filter[key])
      if (filter[key] !=''&&filter[key] === item[key]){
        console.log("Gounded");
        console.log(item[key]);
        return true;
      }
    }
    return false;
  });
  */
  if(this.ville_value==''&&this.type_logement_value==''&&this.type_loyer_value==''){
    this.locations= this.data;
  }
  this.pagedItems=this.locations;
}
slideConfig = {
  autoplay: true,
  autoplaySpeed: 3000,

  slidesToScroll: 1,
  rows: 0,
  "prevArrow": '<a href="#" class="slick-prev"><i class="fi flaticon-back"></i><span class="sr-only">Previous</span></a>',
  "nextArrow": '<a href="#" class="slick-next"><i class="fi flaticon-right-arrow"></i><span class="sr-only">Next</span></a>',
  fade: true,
  lazyLoad: 'progressive',
  dots: false,
  arrows: true,
  adaptiveHeight: true,
  speed: 3000,
  responsive: [{
      breakpoint: 768,
      settings: {
          dots: true,
          arrows: false
      }
  }]
};
 initIsoTop() {
  "use strict";

  var isotopeHolder = $('.isoContentHolder'),
    win = $(window);
    $('.isoFiltersList a').on( "click", function(e){
    e.preventDefault();
    
    $('.isoFiltersList li').removeClass('active');
    $(this).parent('li').addClass('active');
    var selector = $(this).attr('data-filter');
    isotopeHolder.isotope({ filter: selector });
  });
  $('.isoContentHolder').isotope({
    itemSelector: '.isoCol',
    transitionDuration: '0.6s',
    masonry: {
      columnWidth: '.isoCol'
    }
  });
}

// PreLoader init
 initPreLoader() {
    "use strict";

    $('#loader').delay(1000).fadeOut();
}
}
