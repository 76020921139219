import { Component } from '@angular/core';
import { PageAccueilService } from 'src/app/services/page-accueil.service';
import {apiUrl} from '../../../app/config';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  sliders:any[];
  baseUrl:string;
  constructor(private _servicePageAccueil:PageAccueilService){
    this.baseUrl=apiUrl;
   this.GetAllSlider();
  }
  GetAllSlider()
 {
   
  this._servicePageAccueil.getSliders().subscribe((res:any) => {
    this.sliders=res;
  });
 }
}
