import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SocieteService } from 'src/app/services/societe.service';
 
import { MentionLegalesService } from '../services/mentions-legales-service';
import { apiUrl } from '../config';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {
  mentions:any;
  options:any;
  arguments:any[];
  baseUrl:string;
   newArray:any[]=[];
  constructor(private router: Router,private _mentions_legales_service:MentionLegalesService,private titleService: Title) { 
    this.titleService.setTitle("Mentions légales  | Nobel Immobilier");
    this.baseUrl=apiUrl;
  }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        this._mentions_legales_service.GetAllMentionLegales().toPromise().then((res:any) => {
          this.mentions=res;
         
        });
    }
 

}
