//ProgrammesDetailsComponent
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { apiUrl } from 'src/app/config';
import { ServiceRealisation } from 'src/app/services/realisation-service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-realisation-details',
  templateUrl: './realisation-details.component.html',
  styleUrls: ['./realisation-details.component.css']
})
export class RealisationDetailsComponent implements OnInit {
  navigationSubscription;
  baseUrl:string;
  realisation:any;
  private sub: any;
  idRealisation:string;

 
  constructor(private router: Router,private activatedRoute: ActivatedRoute,private _serviceRealisation:ServiceRealisation,private titleService: Title) { 

    this.baseUrl=apiUrl;
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.idRealisation = params['idRealisation']; 
      this._serviceRealisation.GetRealisationById(this.idRealisation).subscribe((result:any)=>{
        console.log(result);
        console.log(result);
    this.realisation=result;
    this.titleService.setTitle(result.nom_realisation+" | Nobel Immobilier");

  
      })
    });
  }
  ngOnInit() {
   
  }
}
