import { Component, OnInit } from '@angular/core';
declare var 	initSlickCarousel: any;

@Component({
  selector: 'app-mostplaces',
  templateUrl: './mostplaces.component.html',
  styleUrls: ['./mostplaces.component.css']
})
export class MostPlacesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
