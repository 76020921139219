import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {apiUrl} from '../../app/config';
import { ProgrammesNeufsService } from '../services/programmes-neufs.service';
import { PagerService } from '../services/page-service';
import { multiFilter } from '../utils/filterArray';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent implements OnInit {
  baseUrl:string;
  programmes:any[];
  data:any[];
  typelogementlist:any[]=[];
  status:any[]=[];
  locations:any[]=[];

  etape_programme_value:string='';
  type_logement_value:string='';
  localisation_value:string='';
  price_value:string='';

   // pager object
   pager: any = {};

   // paged items
   pagedItems: any[];

  constructor(private router: Router,private _programmesService:ProgrammesNeufsService, private pagerService: PagerService,private titleService: Title) { 
    this.titleService.setTitle("Liste des programmes  | Nobel Immobilier");
    this.baseUrl=apiUrl;
    this.GetAllProgrammes();
   }
   removeDups(names) {
    let unique = {};
    var sorted = [];
for (var i = 0; i < names.length; i++) {
  if(names[i])
    sorted.push(names[i].toUpperCase());
}
sorted.sort();
sorted.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }
  

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
  GetAllProgrammes(){
    this._programmesService.GetAllLocation().subscribe((result:any)=>{
     this.programmes=result;
     this.data=result;
      this.data.forEach(element => {
        element.logementdispo.forEach((element2:any) => {
          this.typelogementlist.push(element2.type)
        });
      });
    
      result.forEach((prog:any) => {
        this.status.push(prog.status);
        this.locations.push(prog.location);
     });
     console.log(this.locations);
    this.status=this.removeDups(this.status);
    this.locations=this.removeDups(this.locations);
    this.typelogementlist=this.removeDups(this.typelogementlist);
     this.setPage(1);

    });
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.programmes.length, page);

    // get current page of items
    this.pagedItems = this.programmes.slice(this.pager.startIndex, this.pager.endIndex + 1);
    console.log('Slice from :'+this.pager.startIndex+ ' to '+(+this.pager.endIndex + 1));

}
changeTypeLogement(){
  this.pagedItems=[];
  this.programmes.forEach(el=>{
    el.logementdispo.forEach(element => {
      if(element.type.toUpperCase()==this.type_logement_value.toUpperCase()){
        if(!this.pagedItems.includes(el))
        this.pagedItems.push(el);
      }
    });
  }); 
 
  if(this.etape_programme_value==''&&this.localisation_value==''&&this.type_logement_value==''){
    this.programmes= this.data;
    this.pagedItems=this.programmes;

  }

}
filterProgrammes(){
  var filter={
    status:this.etape_programme_value,
    location:this.localisation_value
  }
  console.log(filter);
  this.programmes=multiFilter(this.data,filter);
/*
  this.programmes= this.data.filter(function(item) {
    for (var key in filter) {
          console.log(item[key]+' : '+ filter[key])
      if (filter[key] !=''&&filter[key] === item[key]){
        console.log(item[key]);
        return true;
      }
     
    }
    return false;
  });
  */
  if(this.etape_programme_value==''&&this.localisation_value==''&&this.type_logement_value==''){
    this.programmes= this.data;
  }
  this.pagedItems=this.programmes;
}

}
