import { Component, OnInit } from '@angular/core';
import { PageAccueilService } from 'src/app/services/page-accueil.service';
declare var 	initSlickCarousel: any;
import {apiUrl} from '../../../app/config';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {
  blocs:any[];
  options:any[];
  baseUrl:string;
  constructor(private _servicePageAccueil:PageAccueilService) {
    this.baseUrl=apiUrl;
    this.GetAllBlocks();
    this.GetAllOptions();

   }

  ngOnInit() {
  }
  GetAllBlocks()
  {
    
   this._servicePageAccueil.getBlocksAccueil().subscribe((res:any) => {
     this.blocs=res;
   });
  }
  GetAllOptions(){
    this._servicePageAccueil.getOptionsHome().subscribe((res:any) => {
      this.options=res;
      console.log(this.options);
    });
  }
}
