import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public linkRoute: string = "";
  public isHome:boolean;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          this.linkRoute=event.url;
          if(this.linkRoute=="/"){
            this.isHome=false;
          }
          else
          {
            this.isHome=true;
          }
          console.log(event.url);
      }     
  });
  }
}
