import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Option } from '../data/option';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {apiUrl} from '../config';

@Injectable()
export class MentionLegalesService  {
    

  private time: Date = new Date;

  constructor(private http: HttpClient){

  }
  
  header = {
    headers: new HttpHeaders({

      'x-access-token': '44'
    })
  };

 
  GetAllMentionLegales(){
    return this.http.get(apiUrl + '/MentionLegales/getall', this.header);

}
  
 
  

  


}
